@import 'resources/colors';
@import 'resources/mixins';
@import 'resources/variables';

.button {
    height: 39px;
    color: $white;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    background-color: $very-light-grey-color;
    @include border-radius(2em);
    border: none;
    cursor: pointer;
    box-sizing: content-box;
    padding: 0 6px;
    padding-right: 12px;
}

.disabled {
    // should be calculated from $very-light-grey-color
    background-color: rgba(191, 201, 205, 0.6);
    cursor: default;
}

:not(.disabled).button:active {
    background-color: lighten($very-light-grey-color, 10%);
}

.icon {
    color: $white;
    transform: rotate(180deg);
    font-size: 18px;
    margin-right: $icon-margin;
}
