@import 'resources/colors';
@import 'resources/mixins';

.content {
    margin-bottom: 0.5rem;
    border-radius: 3px;
    margin: 0.4em;
}

.contentSelected {
    border: solid 3px $white;
    box-shadow: 0 3px 10px 0 $color-shadow;
    border-radius: 5px;
    margin: 5px;
}

.stripe {
    cursor: pointer;
    @include aspect-ratio(3, 2);
}
