@import 'resources/colors';
@import 'resources/mixins';
@import 'resources/variables';

.button {
    height: 39px;
    width: 114px;
    background-color: $highlight-color;
    font-size: 14px;
    font-weight: 600;
    color: $main-color;
    @include border-radius(2em);
    border: none;
    cursor: pointer;
    box-sizing: content-box;
    padding: 0;
}

.disabled {
    // should be calculated from $highlight-color
    background-color: rgba(235, 255, 15, 0.2);
    cursor: default;
}

:not(.disabled).button:active {
    background-color: lighten($highlight-color, 10%);
}

.icon {
    font-size: 18px;
    margin-left: $icon-margin;
}
