@import 'resources/colors';
@import 'resources/variables.scss';

.container {
    background-color: $main-color;
    color: white;
    min-height: 100vh;
    height: 100%;
    min-width: $sidebar-width;
}

.content {
    padding: 20px 0px;
    height: 100vh;
    box-sizing: border-box;
    width: $sidebar-width;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.section {
    text-align: center;
}

.icon {
    font-size: 25px;
    cursor: pointer;
    color: $white;
}

.iconHome {
    font-size: 26px;
}

.iconLogout {
    margin-top: 15px;
    font-size: 20px;
}

.lang {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $lighter-grey-color;
    text-align: center;
    text-transform: uppercase;
    padding: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 18px;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
}
