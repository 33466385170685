@import 'resources/colors';

.container {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: $light-grey-text-color;
    text-transform: uppercase;
}
