@import 'resources/colors';
@import 'resources/mixins';

.card {
    display: flex;
    flex-direction: column;
    font-size: 0.75em;
    margin: 0 0.67em;
    height: 350px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.factTitleContainer {
    line-height: 1.5;
    margin: 2em 2em 1.5em;
}

.factTitle {
    font-weight: bold;
    text-transform: uppercase;
}

.factHelp {
    color: $lighter-grey-color;
    height: 110px;
}

.factTextarea {
    margin: 0 1.5em;
    height: 120px;
    border: solid 1px rgba(3, 35, 48, 0.1);
    @include border-radius(0.5em);
    outline: none;
    resize: none;
    font: inherit;
    padding: 0.75em;
    line-height: 1.7;
    font-size: 0.9em;
    box-shadow: inset 0.3px 2px 5px 0 rgba(0, 0, 0, 0.27);

    &::-webkit-input-placeholder {
        color: $lighter-grey-color;
    }
    &::-moz-placeholder {
        color: $lighter-grey-color;
    }
    &:-ms-input-placeholder {
        color: $lighter-grey-color;
    }
    &:-o-input-placeholder {
        color: $lighter-grey-color;
    }
}
