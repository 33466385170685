@import 'resources/colors';
@import 'resources/mixins';

.pitchItem {
    display: flex;
    align-items: center;
    margin: 0.6em 0;
}

.number {
    font-size: 0.8em;
    @include border-radius(50%);
    background-color: $highlight-color;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.6em;
}

.label {
    margin-right: 0.6em;
    font-size: 0.94em;
    font-weight: 500;
}

.pitchInput {
    width: 21em;
    height: 2.1em;
    background-color: $background-gray;
    font-size: 0.94em;
}
