@import 'resources/mixins';
@import 'resources/variables';
@import 'resources/colors';

.modal {
    background-color: white;
    padding: 34px;
    @include border-radius(0.3em);
}

.modalOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(3, 35, 48, 0.65);
    z-index: 100;
}

.title {
    font-size: $stripe-font-small;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.titleLine {
    height: 65px;
    flex-direction: column;
    display: flex;
}

.label {
    font-size: $stripe-font-xsmall;
    color: $lighter-grey-color;
    margin-bottom: 5px;
    .comment {
        font-size: $stripe-font-xxsmall;
    }
}

.titleInput {
    height: 33px;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: $stripe-font-xsmall;
}
