@import 'resources/colors';

.button {
    width: 100%;
    padding: 11px;
    border: none;
    font-size: 13px;
    font-weight: 500;
    color: $lighter-grey-color;
    background-color: white;
    cursor: pointer;
    border: solid 1px $background-gray;
}

.disabled {
    // should be calculated from $lighter-grey-color
    color: rgba(150, 169, 179, 0.6);
    cursor: default;
    .addIcon {
        color: rgba(150, 169, 179, 0.6);
    }
}

.addIcon {
    width: 24px;
    height: 24px;
    margin: 5px 9px 9px 0;
    color: $add-icon-grey;
}
