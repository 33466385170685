@import 'resources/mixins';
@import 'resources/variables';
@import 'resources/colors';

.container {
    box-sizing: border-box;
    height: 62px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    @media (max-width: $screen-md) {
        padding: 0.5em;
    }
}

.label {
    font-size: 12px;
    color: $dark-blue;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0 1em;
    @media (max-width: $screen-md) {
        display: none;
    }
}

.stripeTypes {
    flex-grow: 1;
}
