@import url('https://fonts.googleapis.com/css?family=Poppins:regular,medium,bold');

body {
    overflow: auto;
    width: 100%;
    height: 100%;
}

#root {
    display: flex;
    // needed to make work line break with i18n-next
    white-space: pre-line;
}

// prevent cropping of italic text
.DraftEditor-root {
    padding-right: 5px;
}

@import 'resources/colors';

body,
button,
input,
textarea {
    font-family: 'Poppins', sans-serif !important;
    color: $main-color;
}

strong {
    font-weight: bold;
}
