@import 'resources/colors';
@import 'resources/button';

.button {
    @extend .roundedButton;
    background-color: $highlight-color;
    font-weight: 500;
    font-size: 14px;
    padding: 0 15px;
    text-transform: capitalize;
}

.button:hover {
    // background-color: $button-hover;
    // color: white;
}

$disabled-color: rgba(0, 0, 0, 0.3);

.disabled {
    // background-color: $button-blue-disabled;
    color: $disabled-color;
    cursor: default;
}

.button.disabled:hover {
    // background-color: $button-blue-disabled;
    color: $disabled-color;
}
