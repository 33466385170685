.title {
    width: 100%;
    font-size: 50px;
    line-height: 1.1;
    font-weight: bold;
    margin-bottom: 1em;
}

.buttons {
    display: flex;
    position: fixed;
    bottom: 15px;
    right: 20px;
    z-index: 1;
}

.button {
    margin-right: 0.6em;
    &:last-child {
        margin-right: 0;
    }
}

.factsPage {
    .factsContainer {
        display: flex;
    }
    .factsContent {
        box-sizing: border-box;
        height: calc(100vh - 60px); // $progress-bar-height
        max-height: calc(100vh - 60px); // $progress-bar-height
        padding: 20px;
        padding-top: 60px;
        flex: 1;
        overflow: scroll;
    }
    .factsHelpZone {
        height: calc(100vh - 60px); // $progress-bar-height;
    }
}
