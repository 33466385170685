@import 'resources/colors';

.howTo {
    margin: 0 0.75em;
}

.cardContainer {
    width: (100% / 3);
    display: inline-block;
    margin-bottom: 66px;
    float: left;
}

.intro {
    margin: 1.2em 0 0.8em;
    padding: 0.8em 0;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.4;
    border-style: solid;
    border-color: $light-grey-color;
    border-width: 1px 0px;
}

.info {
    font-weight: normal;
    line-height: 1.6;
    color: $light-grey-color;
    font-size: 0.85em;
}
