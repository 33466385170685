@import 'resources/colors';

.bannerDark {
    background-color: $transition-banner-dark;
}

.bannerLight {
    background-color: $transition-banner-light;
}

.deleteIconContainer {
    position: absolute;
    bottom: 5px;
    left: 8px;
    border-radius: 5px;
    font-size: 22px;
    color: white;
    &:hover {
        color: #ececec;
    }
}
