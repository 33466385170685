@import 'resources/colors';
@import '../../pages/PitchPage/PitchItem/index.module.scss';

.userFormMessage {
    height: 40px;
    display: flex;
    align-items: center;
}

.userFormCheckbox {
    height: 20px;
}
