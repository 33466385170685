@import 'resources/variables';

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.containerSingleColumn {
    padding: 30px 120px;
}

.title {
    text-align: center;
    max-height: 260px;
}

.keyDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}

.keyDataRow {
    justify-content: center;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
