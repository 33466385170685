.container {
    display: flex;
    flex-direction: column;
}

.button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.dateField {
    width: 140px;
}

.labelContainer {
    display: flex;
    flex-direction: row;
    height: 30px;
    padding: 5px 0;
}

.label {
    width: 260px;
}

.message {
    height: 40px;
    display: flex;
    align-items: center;
}

.error {
    color: red;
}

.daysLeft {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.durationUnit {
    width: 50px !important;
}
