@import 'resources/variables';

.absolute {
    position: absolute;
}

.title {
    color: white;
}

.smallTitle {
    font-size: $stripe-font-small;
}
.mediumTitle {
    font-size: $stripe-font-medium;
}
.largeTitle {
    font-size: $stripe-font-large;
}
