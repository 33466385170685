@import 'resources/colors';

.container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 40px;
    color: $lighter-grey-color;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}

.icon {
    font-size: 18px;
    margin-right: 10px;
}
