@import 'resources/mixins';
@import 'resources/colors';
@import 'resources/variables';

.container {
    display: flex;
    flex-direction: column;
    padding-bottom: 65px;
    background-color: $white;
    border: solid 1px $border-grey;
    width: $help-zone-width;
    box-sizing: border-box;
}

.header {
    height: 90px;
    min-height: 80px;
    box-sizing: border-box;
    padding: 10px;
    background-color: $highlight-color;
    display: flex;
    flex-direction: column;
}

.headerCentered {
    justify-content: center;
    align-items: center;
}

.step {
    font-size: 20px;
    font-weight: 600;
    color: $main-color;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 600;
    max-width: 100%; //fixing IE11 overflow
}

.position {
    font-size: 30px;
    margin-left: 5px;
    font-weight: 600;
}

.contentContainer {
    overflow-y: auto;
    padding: 1em;
    .subTitle {
        text-transform: uppercase;
        font-size: 0.65em;
        font-weight: 600;
        margin-bottom: 0.7em;
    }
    .description {
        color: $dark-blue;
        font-size: 0.7em;
        line-height: 1.33;
        margin-bottom: 1.5em;
        font-weight: 400;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
