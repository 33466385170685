@import 'resources/colors';
@import 'resources/variables';

.button {
    border: dashed 0.05em $stripe-grey-border-color;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: $stripe-font-small;
    padding: 1em;
    color: white;
    text-align: center;
    cursor: pointer;
}

.icon {
    font-size: 1.5em;
    margin-bottom: 0.5em;
}
