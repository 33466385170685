@import 'resources/colors';

.container {
    display: flex;
    font-size: 12px;
    color: $lighter-grey-color;
}

.message {
    margin-right: 5px;
}

.link {
    color: $lighter-grey-color;
    cursor: pointer;
    text-decoration: underline;
}
