@import 'resources/colors';
@import 'resources/variables';

.quotation {
    align-self: stretch;
    margin-bottom: 5px;
}

.quotationDisplay {
    align-self: stretch;
    margin-bottom: 5px;
    text-align: left;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size: $stripe-font-small;
    line-height: 1.2;
    max-width: 380px;
}

.author {
    align-self: stretch;
    font-style: italic;
}

.authorDisplay {
    font-style: italic;
    font-size: $stripe-font-small;
    text-align: left;
    margin-top: 40px;
    line-height: 1.2;
}

.quotationBox {
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    border-radius: 5px;
}

.quotationBoxDouble {
    width: 325px;
    &:first-child {
        margin-right: 20px;
    }
}

.quotationBoxEdit {
    border: dotted 0.05em $stripe-grey-border-color;
    padding: 5px;
}

.quotationText {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.iconQuotationMark {
    font-size: 25px;
    margin-right: 8px;
    min-width: 40px;
}

.delete {
    color: #ff0000;
    align-self: flex-end;
    font-size: 0.75em;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 5px;
    text-transform: capitalize;
}

.darkColor {
    color: $dark-color;
}

.lightColor {
    color: $light-color;
}
