.title {
    font-size: 25px;
    margin-top: 20px;
}

.subTitle {
    font-size: 15px;
    line-height: 1.2;
}

.closeButton {
    margin-top: 30px;
    width: 80px;
    text-transform: uppercase;
}
