@import 'resources/mixins';
@import 'resources/colors';
@import 'resources/variables';

$progress-bar-height: 60px;

.container {
    width: 100%;
    height: $top-bar-height;
    background-color: $white;
    display: flex;
    flex-direction: column;
}

.privacyIndicator {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60px;
    .indicatorColor {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: $red-private;
    }
    .indicatorColor.public {
        background-color: $light-duckblue;
    }
    .name {
        font-size: 12px;
        font-weight: 500;
    }
}

.progressBarContainer {
    height: $progress-bar-height;
}

.actions {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    padding-right: 24px;
}

.button {
    margin-left: 10px;
}

.link {
    text-decoration-line: none;
}
