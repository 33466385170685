@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin border-radius($border-radius) {
    -moz-border-radius: $border-radius;
    -webkit-border-radius: $border-radius;
    border-radius: $border-radius;
}

@mixin placeholder-color($color) {
    &::-webkit-input-placeholder {
        color: $color;
    }
    &::-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
    &:-o-input-placeholder {
        color: $color;
    }
}

@mixin scaling-content($width, $height) {
    position: absolute;
    display: flex;
    flex: 1;
    width: $width;
    height: $height;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    box-sizing: content-box;
}
