@import 'resources/colors';

.modal {
    padding: 0px !important;
    width: 460px;
}

.buttons {
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
    padding: 0 60px;
}

.topContainer {
    height: 220px;
    background-image: url('../../resources/img/modal-background.png');
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    font-size: 150px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 60px;
}

.title {
    margin-top: 20px;
    margin-bottom: 35px;
    font-size: 18px;
    font-weight: bold;
}

.subTitle {
    font-size: 13px;
    color: $dark-blue;
    line-height: 18px;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    line-height: 1.5em;
    color: $lighter-grey-color;
}

.licenseErrorMessagge {
    text-align: center;
    margin: 20px 0;
}

.licenseButtons {
    text-transform: initial;
}
