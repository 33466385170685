@import 'resources/colors';

$sidebarWidth: 272px;

.deleteModalMessage {
    text-align: center;
    font-size: 16px;
    color: $main-color;
    font-weight: 500;
}

.deleteModalStoryTitle {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    color: $lighter-grey-color;
    font-weight: 500;
}

.storyContainer {
    display: flex;
}

.homepage {
    width: calc(100% - #{$sidebarWidth});
    background-image: url('../../resources/img/bg-dashboard.jpg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: $background-azur;
    display: flex;
    align-items: center;
}

.content {
    margin: 80px;
}

.welcome {
    font-size: 72px;
    line-height: 0.9;
    color: white;
    font-weight: bold;
}

.yourStories {
    padding: 16px 19px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: $main-color;
    border: solid 1px $background-gray;
}

.sidebar {
    width: $sidebarWidth;
    padding: 0;
}
