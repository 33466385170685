@import 'resources/colors';
@import 'resources/mixins';

.modal {
    width: 500px;
    display: flex;
    flex-direction: column;
}

.check {
    align-self: center;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 40px;
    color: $highlight-color;
    border: 2px solid $highlight-color;
    @include border-radius(50%);
}
