@import 'resources/colors';
@import 'resources/variables';
@import 'resources/button';

$flat-button-height: 25px;

.button {
    @extend .roundedButton;
    font-size: 13px;
    font-weight: 500;
    background-color: $background-gray;
    color: $main-color;
    padding: 0px 15px;
}

.flatButton {
    height: $flat-button-height;
}

.icon {
    margin-right: $icon-margin;
}
