@import 'resources/colors';
@import 'resources/button';

.button {
    @extend .roundedButton;
    width: 7.4em;
    border: solid 1px #e5e9ea;
    background-color: white;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
}

.button:hover {
    border: solid 1px $lighter-grey-color;
}
