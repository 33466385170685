.container {
    display: flex;
    flex-direction: column;
}

.button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
