@import 'resources/colors';
@import 'resources/mixins';

.container {
    display: flex;
}

.formContainer {
    width: 500px;
    background-color: white;
    @include border-radius(5px 0 0 5px);
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
