@import 'resources/colors';
@import 'resources/mixins';

.modal {
    box-sizing: border-box;
    width: 530px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.toggleContainer {
    display: flex;
    align-items: center;
}

.toggleLabel {
    font-size: 16px;
    font-weight: 600;
}

.toggleLabelInactive {
    color: $very-light-grey-color;
}

.toggleLabelLeft {
    margin-right: 14px;
}

.toggleLabelRight {
    margin-left: 14px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.description {
    font-size: 12px;
    font-weight: 500;
    color: $lighter-grey-color;
    line-height: 1.25;
    margin: 15px 0 40px 0;
}

.link {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
}

.linkLabel {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 12px;
}

.linkLabelInactive {
    color: $red-private;
}

.linkInput {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border: solid 1px $very-light-grey-color;
    @include border-radius(5px);
}

.linkInputInactive {
    border: solid 1px $background-gray;
}

.inputField {
    flex: 1;
    overflow: scroll;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 20px 0;
    margin-right: 20px;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
}

.inputFieldInactive {
    color: $background-gray;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.copyButton {
    height: 32px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.1s ease-in-out;
}

.copyButton:disabled,
.copyButton:hover:disabled {
    background-color: $background-gray;
    color: $very-light-grey-color;
    cursor: default;
}

.copyButton:not(disabled) {
    background: $highlight-color;
}

.copyButton:hover:not(disabled) {
    background-color: darken($highlight-color, 5%);
}

.copyButton:active:not(disabled) {
    box-shadow: 0 0 0 2px $lighter-grey-color;
    outline: 0;
}

.confirmationMessage {
    position: absolute;
    bottom: 10px;
    right: 35px;
    visibility: visible;
    opacity: 1;
}

.confirmationMessageHidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
}
