$screen-md: 1023px;

$base-stripe-width: 1024px;
$base-stripe-height: 693px;
$sidebar-width: 45px;
$storybar-width: 220px;
$help-zone-width: 255px;
$footer-width: 1280px;
$footer-height: 420px;
$top-bar-height: 100px;
$progress-bar-height: 60px;
$stripe-font-xxsmall: 11px;
$stripe-font-xsmall: 13px;
$stripe-font-small: 16px;
$stripe-font-medium: 24px;
$stripe-font-large: 48px;
$transition-stripe-text-height: 150px;
$icon-margin: 6px;
