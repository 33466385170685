@import 'resources/mixins';
@import 'resources/colors';

.container {
    display: flex;
    flex-direction: column;
    width: 270px;
    padding-bottom: 65px;
    background-color: white;
    @include border-radius(0.2em);
    box-sizing: content-box;
    box-shadow: 0px 0.6em 1.6em 0 $color-shadow;
}

.titleContainer {
    background-color: $highlight-color;
    padding: 0 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 44px;
    border-bottom: 1px solid $border-grey;
    align-items: baseline;
    .title {
        height: 100%;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.75em;
        font-weight: 600;
        flex-grow: 1;
    }
    .icon {
        color: $main-color;
    }
}

.contentContainer {
    max-height: 184px;
    overflow: scroll;
    padding: 1em;
    border-bottom: 1px solid $border-grey;
    .subTitle {
        color: $regular-duckblue;
        text-transform: uppercase;
        font-size: 0.65em;
        font-weight: 600;
        margin-bottom: 0.7em;
    }
    .description {
        color: $main-color;
        font-size: 0.7em;
        line-height: 1.33;
        margin-bottom: 1.5em;
        font-weight: 400;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
