@import 'resources/variables.scss';

$height: 60px;

.container {
    min-width: 1000px - $sidebar-width;
    width: 100%;
}

.content {
    position: relative;
    width: 100%;
    height: calc(100vh - 100px); // $top-bar-height
    overflow: hidden;
    display: flex;
}

.helpzone {
    height: calc(100vh - 100px); // $top-bar-height
}

.editor {
    flex: 1;
}
