@import 'resources/colors';

.button {
    font-size: 14px;
    padding: 0 25px;
    height: 44px;
    box-sizing: content-box;
    font-weight: 600;
    line-height: 1.7;
    text-align: center;
    color: $main-color;
    background-color: $highlight-color;
    border-radius: 27px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
}
