.modal {
    padding: 0px !important;
    width: 460px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
}

.title {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
}

.subTitle {
    font-size: 13px;
    line-height: 18px;
}

.closeButton {
    margin-top: 30px;
    width: 120px;
    text-transform: uppercase;
}

.icon {
    margin-bottom: 30px;
}
