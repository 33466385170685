@import 'resources/mixins';
@import 'resources/colors';
@import 'resources/variables';

.scrollingArea {
    overflow-y: auto;
}

.container {
    min-height: 480px;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border: solid 1px $border-grey;
    width: $help-zone-width;
    box-sizing: border-box;
}

.content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.buttonContainer {
    min-height: 60px;
}

.header {
    height: 40px;
    box-sizing: border-box;
    padding: 10px;
    background-color: $highlight-color;
}

.titleContainer {
    cursor: pointer;
    min-height: 30px;
    padding-left: 10px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid $border-grey;
    box-sizing: border-box;
}

.openTitleContainer {
    background-color: $highlight-color;
}

.position {
    width: 26px;
}

.title {
    flex: 1;
}

.contentContainer {
    overflow-y: visible;
    padding: 10px;
    padding-bottom: 0;
}

.fadeout {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0em;
    width: 100%;
    height: 40px;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.arrow {
    font-size: 12px;
    color: $main-color;
}

.arrowUp {
    transform: rotate(-90deg);
}

.arrowDown {
    transform: rotate(90deg);
}
