@import 'resources/colors';

.title {
    text-align: center;
    margin-bottom: 35px;
    max-height: 260px;
    padding-top: 50px;
}

.titleDisplay {
    margin-bottom: 50px;
}

.container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 40px 80px;
}

.content {
    display: flex;
}
