@import './variables';

$toastr-width: 160px;
$successBgColor: #032330;
rrt-confirm-holder {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;

    .shadow {
        width: 100%;
        height: 100%;
        background-color: rgba(50, 58, 68, 0.8);
    }

    .rrt-confirm {
        width: 320px;
        background-color: white;
        position: absolute;
        z-index: 9;
        top: 20%;
        left: 50%;
        margin-left: -160px;
        box-shadow: 3px 3px 20px #333;
        border-radius: 4px;
        overflow: hidden;

        .rrt-message {
            width: 100%;
            padding: 5%;
            min-height: 50px;
            font-size: 1em;
            background-color: white;
            text-align: center;
            clear: both;
        }

        .rrt-buttons-holder {
            display: flex;

            .rrt-button {
                flex-grow: 1;
                height: 50px;
                text-transform: capitalize;
                border: none;
                background-color: transparent;
                padding: 0;
                margin: 0;
                float: left;
                border-top: 1px solid #f0f0f0;
                font-size: 14px;
                overflow: hidden;
                cursor: pointer;

                &:hover {
                    background-color: #f5f5f5;
                }

                &.rrt-ok-btn {
                    &:active {
                        background-color: $successBgColor;
                        color: white;
                    }
                }

                &.rrt-cancel-btn {
                    &:active {
                        background-color: $errorBgColor;
                        color: white;
                    }
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
}
