@import 'resources/mixins';
@import 'resources/variables';

.container {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.buttons {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
}

.validateButton {
    padding: 0 20px;
    margin-left: 10px;
}

.cancelButton {
    margin-left: 10px;
}
