@import 'resources/variables';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-height: 33%;
    padding: 30px 20px;
    box-sizing: border-box;
    z-index: 0;
}

.transitionTitle {
    text-align: center;
    max-height: $transition-stripe-text-height;
    overflow: hidden;
}
