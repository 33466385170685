@import 'resources/colors';

.list {
    margin-top: 40px;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 40px;
}

.searchInput {
    height: 50px;
    margin-right: 20px;
    box-sizing: border-box;
    font-size: 16px;
}

.pagination {
    display: flex;
}

.paginationInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $lighter-grey-color;
    font-size: 16px;
    width: 60px;
}

.pagination button {
    color: black;
    font-size: 24px;
    padding: 7px 24px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
}

.pagination button:hover:not(.disabled) {
    background-color: $lighter-grey-color;
}

.pagination button.disabled {
    color: $very-light-grey-color;
    cursor: not-allowed;
}

// Header

.header {
    font-weight: bold;
    position: sticky;
    top: 0;
    background: white;
}

// UserListItem + Header

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.item {
    font-size: 11px;
    width: 180px;
    padding: 10px 6px;
    border-bottom: 1px solid rgba(3, 35, 48, 0.2);
    text-align: left;
    overflow: scroll;
}

.nameItem {
    width: 100px;
}

.firstNameItem {
    width: 70px;
}

.numberItem {
    width: 35px;
    text-align: center;
}

.dateItem {
    width: 65px;
}

.mediumItem {
    width: 80px;
}

.itemShort {
    width: 35px;
}

.indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.active {
    @extend .indicator;
    background: green;
}

.inactive {
    @extend .indicator;
    background: red;
}

.notApplied {
    @extend .indicator;
    background: gray;
}

.pending {
    @extend .indicator;
    background: blue;
}

.button {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 10px;
    width: 50px;
    height: 25px;
}
