@import 'resources/mixins';
@import 'resources/colors';
@import 'resources/variables';

.textarea {
    border-radius: 0.1em;
    background-color: transparent;
    border: dashed 0.05em $stripe-grey-border-color;
    padding: 10px;
}

.borderSolid {
    border-style: solid;
}

.borderRadius {
    @include border-radius(5px);
}

.xsmallTextarea {
    font-size: $stripe-font-xsmall;
}

.smallTextarea {
    font-size: $stripe-font-small;
}
.mediumTextarea {
    font-size: $stripe-font-medium;
}
.largeTextarea {
    font-size: $stripe-font-large;
}
