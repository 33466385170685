@import 'resources/variables';
@import 'resources/mixins';

.background {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.backgroundImageContained {
    background-size: contain;
}

.backgroundFixed {
    background-attachment: fixed;
    //fixed image does not work for ipad
    @media only screen and (max-device-width: 1023px) {
        background-attachment: scroll;
    }
}

.childContainer {
    @include scaling-content($base-stripe-width, $base-stripe-height);
}

.banner {
    width: 100%;
    position: absolute;
    left: 0;
}
