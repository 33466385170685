@import 'resources/colors';

.modalTitle {
    align-self: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    max-width: 100%; //fixing IE11 overflow
}

.modalSubtitle {
    align-self: center;
    color: $lighter-grey-color;
    font-size: 12px;
    line-height: 1.33;
    margin-bottom: 20px;
    max-width: 100%; //fixing IE11 overflow
}
