.content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../resources/img/bg-login.jpg');
    background-size: cover;
    min-height: 100vh;
    flex-grow: 1;
}

.container {
    display: flex;
}

.formContainer {
    display: flex;
    flex-direction: column;
}

.button {
    margin-top: 25px;
    width: 120px;
    align-self: center;
}

.loginError {
    height: 16px;
}

.loginTitle {
    margin-bottom: 50px;
}

.links {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-content: flex-end;
}
