.container {
    position: relative;
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 30px 20px;
}

.fakeIframe {
    justify-content: center;
    align-items: center;
    width: 640px;
    height: 360px;
    border: 10px dashed #f1f1f1;
    color: #eee;
    display: flex;
}

.fakeIframe p {
    flex: 1;
    align-self: center;
    text-align: center;
    font-size: 3em;
}
