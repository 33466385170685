$background-gray: #eef2f4;
$main-color: #032330;
$light-grey-color: #848e93;
$lighter-grey-color: #96a9b3;
$very-light-grey-color: #bfc9cd;
$lightest-grey-color: #f7f7f7;
$highlight-color: #ebff0f;
$stripe-grey-border-color: #c7d2d7;
$stripe-grey-background-color: #a2b4bc;
$color-shadow: rgba(3, 35, 48, 0.35);
$button-background-color: rgba(146, 166, 174, 0.1);
$border-grey: #f0f4f5;
$light-grey-text-color: #98abb6;
$dark-blue: #697e89;
$regular-duckblue: #06bbb2;
$light-duckblue: #56e2db;
$yellow: #fef915;
$white: #ffffff;

$button-blue: rgba(86, 226, 219, 1);
$link-color: #2ad2c9;
$button-blue-disabled: rgba(86, 226, 219, 0.3);
$button-hover: #042530;
$red-alert: #ff0000;
$red-private: #fd6363;
$transition-banner-dark: rgba(3, 35, 48, 0.9);
$transition-banner-light: rgba(255, 255, 255, 0.9);
$background-azur: #56e1da;
$add-icon-grey: #c5d2d7;
$story-box-shadow: rgba(3, 35, 48, 0.2);
$story-after-background: #bec4c8;
$story-border: #a9b0b6;

$dark-color: $main-color;
$light-color: #ffffff;
