@import 'resources/colors';
@import 'resources/mixins';

.container {
    height: 100%;
    @include border-radius(0 5px 5px 0);
    box-sizing: border-box;
    width: 480px;
    background-color: $main-color;
    padding: 40px;
    color: $white;
}

.loadedContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 12px;
}

.subTitle {
    font-size: 12px;
    line-height: 1.4em;
    text-align: center;
    width: 300px;
    margin-bottom: 8px;
}

.image {
    background-image: url('../../../../resources/img/visuel-login.png');
    background-repeat: no-repeat;
    height: 200px;
    width: 100%;
}
