@import 'resources/colors';

.container {
    display: flex;
    flex-direction: column;
    width: 260px;
}

.buttons {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
}

.nextButton {
    margin-left: 10px;
}

.title {
    color: $main-color;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
    line-height: 1.3em;
}

.content {
    color: $lighter-grey-color;
    font-size: 13px;
    line-height: 1.5em;
}
