$bubble-size: 10px;

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullpageContainer {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    display: table;
    width: 60px;
}

.loader {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    min-height: 100%;
    display: table-cell;
    vertical-align: middle;
}

.bubbles {
    margin: auto;
}

.bubbleContainer {
    display: inline-block;
    width: $bubble-size;
    height: $bubble-size;
    margin: 0 5px;
}

.bubble {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    animation: bubble 1.2s -0.6s infinite ease-out;
}

.bubbleSecond {
    animation-delay: -0.3s;
}

.bubbleThird {
    animation-delay: 0s;
}

@keyframes bubble {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
