@import 'resources/mixins';
@import 'resources/colors';
@import 'resources/variables';

.container {
    box-sizing: border-box;
    min-height: $progress-bar-height;
    background-color: $main-color;
    display: flex;
    padding: 0 13px 15px 13px;
    align-items: flex-end;
}

.section {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: $dark-blue;
    font-size: 12px;
    font-weight: 600;
    padding: 0px 5px;
}

.sectionClickable {
    cursor: pointer;
}

.sectionCompleted {
    color: $white;
    .bar {
        background-color: $white;
    }
}

.sectionCurrent {
    color: $highlight-color;
    .bar {
        background-color: $highlight-color;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.bar {
    margin-top: 5px;
    height: 3px;
    width: 100%;
    background-color: $dark-blue;
    @include border-radius(10px);
}
