@import 'resources/variables';
@import 'resources/colors';
@import 'resources/mixins';

.container {
    position: relative;
    @include scaling-content($base-stripe-width, $base-stripe-height);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border-radius: 0.5em;
    border: solid 0.5em white;
    box-sizing: content-box;
}

.imageContained {
    background-size: contain;
}

.title {
    position: absolute;
    bottom: -20%;
    left: 5%;
    right: 5%;
    font-size: 60px;
    font-weight: 600;
    height: 80px;
    color: $main-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
