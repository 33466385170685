@import 'resources/colors.scss';

.container {
    box-sizing: border-box;
    min-width: 600px;
    width: 100%;
    min-height: 100vh;
    background-color: $light-duckblue;
    padding: 10%;
    padding-top: 6%;
    padding-bottom: 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-image: url('../../resources/img/background-intro-EN.png');
    background-size: contain;
    background-position: center;
}

.containerFR {
    background-image: url('../../resources/img/background-intro-FR.png');
}

.title {
    color: $white;
    font-size: 50px;
    line-height: 1.31;
}

.titleFirstLine {
    font-weight: 600;
}

.startButton {
    align-self: center;
    width: auto;
    height: 44px;
    padding-left: 25px;
    padding-right: 22px;
}
