// not to import in index.scss
@import 'resources/mixins';
@import 'resources/variables';

.roundedButton {
    height: 44px;
    font-size: 0.75em;
    @include border-radius(2em);
    border: none;
    cursor: pointer;
    @media (max-width: $screen-md) {
        font-size: 0.65em;
    }
}
