@import 'resources/colors';

.header {
    display: flex;
    background: $white;
}

.progressBar {
    flex: 1;
}
