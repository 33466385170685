@import 'resources/mixins';
@import 'resources/colors';

::placeholder {
    color: $lighter-grey-color;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $lighter-grey-color;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $lighter-grey-color;
}

.input {
    border: solid 1px rgba(3, 35, 48, 0.2);
    padding: 0 0.5em 0.25em 0.5em;
    @include border-radius(0.5em);
    box-sizing: content-box;
    font-weight: 500;
}

.inputError {
    border: solid 1px $red-alert;
}

.loginInput {
    font-size: 13px;
    padding: 15px;
    margin-top: 10px;
    color: $main-color;
}

.select {
    border: none;
    width: 180px;
    font-weight: 500;
}
