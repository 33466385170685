@import 'resources/colors';
@import 'resources/mixins';

.firstStripe {
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 4px 16px 4px $story-box-shadow;

    &:before,
    &:after {
        display: block;
        content: '';
        position: absolute;
        border-radius: 0 0 6px 6px;
        height: 3px;
        background-color: $story-after-background;
        border-bottom: 1px solid $story-border;
    }

    &:before {
        top: 100%;
        left: 7px;
        width: calc(100% - 14px);
        box-shadow: 0 3px 8px 0px $story-box-shadow;
    }

    &:after {
        top: calc(100% + 4px);
        left: 14px;
        width: calc(100% - 28px);
        box-shadow: 0 7px 16px 1px $story-box-shadow;
    }
}

.story {
    margin-bottom: 40px;
    @include aspect-ratio(3, 2);
}
