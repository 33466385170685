@import 'resources/colors';

.modal {
    width: 460px;
}

.icon {
    font-size: 100px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title {
    margin-top: 20px;
    margin-bottom: 35px;
    font-size: 18px;
    font-weight: bold;
    max-width: 100%; //fixing IE11 overflow
}

.subTitle {
    font-size: 13px;
    color: $dark-blue;
    line-height: 18px;
    max-width: 100%; //fixing IE11 overflow
}

.closeButton {
    margin-top: 30px;
    width: 80px;
    text-transform: uppercase;
}
