@import 'resources/mixins';
@import 'resources/colors';
@import 'resources/variables';

.container {
    display: flex;
    flex-grow: 1;
    min-height: 90px;
    //needed to keep width independently of the content
    width: 300px;
}

.keyDataContainer {
    display: flex;
    flex-direction: column;
    margin: 0.5em;
    flex-grow: 1;
    background-color: white;
    padding: 20px;
    text-align: center;
    line-height: 1.5;
}

.keyDataContainerEdit {
    padding: 5px;
}

.boxShadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden;
}

.data {
    color: $lighter-grey-color;
    @include placeholder-color($lighter-grey-color);
    text-align: center;
    color: rgb(4, 136, 196);
}

.dataDisplay {
    font-size: $stripe-font-large;
    color: rgb(4, 136, 196);
    word-wrap: break-word;
    white-space: pre-wrap;
}

.paragraph {
    color: $main-color;
    @include placeholder-color($main-color);
    text-align: center;
}

.paragraphDisplay {
    font-size: $stripe-font-small;
    word-wrap: break-word;
    white-space: pre-wrap;
}
