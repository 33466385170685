@import 'resources/colors';
@import 'resources/mixins';

.container {
    font-size: 10px;
    font-weight: 500;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color: #f1f9fc;
    @include border-radius(14px);
}

.error {
    background: $red-alert;
}

.icon {
    margin-right: 6px;
    font-size: 14px;
    font-weight: 600;
}
