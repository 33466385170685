@import 'resources/colors';
@import 'resources/variables';
@import 'resources/mixins';

.container {
    display: flex;
    flex-direction: column;
    height: 100%;

    background-color: $background-gray;
    padding: 0 14px;
}

.stripeContainer {
    box-shadow: 0px 5px 10px 0 rgba(3, 35, 48, 0.35);
    border: solid 3px $white;
    margin-bottom: 14px;
    flex: 1;

    // @see http://stackoverflow.com/questions/15381172/css-flexbox-child-height-100
    position: relative;
    & > * {
        position: absolute;
    }
}

.customizationBar {
    width: 100%;
}
